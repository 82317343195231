import React, { PureComponent } from 'react';
import { cn } from '@bem-react/classname';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { BASE_PATH } from '../../constants';

import './SliderGame.sass';


class SliderGame extends PureComponent {
    static propTypes = {
        vertical: PropTypes.bool.isRequired,
        previews: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                type: PropTypes.string,
                file: PropTypes.string,
            }),
        ).isRequired,
    };

    constructor(props) {
        super(props);
        this.sliderRef = React.createRef();
        this.thumbnailRef = React.createRef();
    }

    state = {
        slider: null,
        thumbnail: null,
    };

    componentWillUpdate(nextProps, nextState) {
        const { slider, thumbnail } = nextState;

        if (nextState.slider && nextState.thumbnail) {
            slider.current.swiper.controller.control = thumbnail.current.swiper;
            thumbnail.current.swiper.controller.control = slider.current.swiper;
        }
    }

    getMcn = () => cn('SliderGame');

    sliderParams = () => {
        const { vertical } = this.props;

        return {
            slidesPerView: vertical ? 2 : 1,
            spaceBetween: 10,
            observer: true,
            navigation: {
                nextEl: '.SliderGame-Button.SliderGame-Button_type_next',
                prevEl: '.SliderGame-Button.SliderGame-Button_type_prev',
            },
            on: {
                init: () => {
                    this.setState({
                        slider: this.sliderRef,
                    });
                },
            },
        };
    };

    thymbnailParams = () => {
        const { vertical } = this.props;

        return {
            spaceBetween: 10,
            slidesPerView: vertical ? 3 : 2,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            observer: true,
            pagination: {
                el: '.swiper-pagination',
            },
            renderPagination: () => (
                <span className="swiper-pagination SliderGame-Pagination">
                    &nbsp;
                </span>
            ),
            on: {
                init: () => {
                    this.setState({
                        thumbnail: this.thumbnailRef,
                    });
                },
            },
            breakpointsInverse: true,
            breakpoints: {
                520: {
                    slidesPerView: vertical ? 4 : 3,
                },
            },
        };
    };

    renderItem = (item) => {
        const { id, link } = item;

        return (
            <div className={this.getMcn()('Item')} key={id}>
                <img src={`${BASE_PATH}/bstorage/${link}`} alt="" className={this.getMcn()('Img')} />
            </div>
        );
    };

    renderThumbnailItem = (item) => {
        const { id, link } = item;

        return (
            <div key={id}>
                <img src={`${BASE_PATH}/bstorage/${link}`} alt="" className={this.getMcn()('ThumbImg')} />
            </div>
        );
    };

    renderItems = () => {
        try {
            const { previews } = this.props;

            return (
                <>
                    <div className={this.getMcn()('SwiperMain')}>
                        <Swiper
                            {...this.sliderParams()}
                            ref={this.sliderRef}
                        >
                            {previews.map(item => this.renderItem(item))}
                        </Swiper>
                    </div>
                    <div className={this.getMcn()('SwiperThumb')}>
                        <Swiper
                            {...this.thymbnailParams()}
                            ref={this.thumbnailRef}
                        >
                            {previews.map(item => this.renderThumbnailItem(item))}
                        </Swiper>
                    </div>
                </>
            );
        } catch (e) {
            return null;
        }
    };

    render() {
        const { previews } = this.props;

        return (
            <div className={this.getMcn()()}>
                {previews.length ? this.renderItems() : null}
            </div>
        );
    }
}

export default SliderGame;
