import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import Swiper from 'react-id-swiper';

import './TagsFilter.sass';


class TagsFilter extends Component {
    static propTypes = {
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        onFilterSet: PropTypes.func.isRequired,
        current: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        bodyColor: PropTypes.string.isRequired,
    };

    getMcn = () => cn('TagsFilter');

    sliderParams = () => ({
        slidesPerView: 'auto',
        spaceBetween: 15,
        breakpointsInverse: true,
        breakpoints: {
            520: {
                spaceBetween: 30,
            },
        },
    });

    renderItem = (item) => {
        const {
            current, color, bodyColor, onFilterSet,
        } = this.props;
        const activeFilter = current === '' ? 'all' : current;

        return (
            <div className={this.getMcn()('Item')} key={item}>
                <button
                    type="button"
                    onClick={() => onFilterSet(item !== 'all' ? item : '')}
                    className={classnames(this.getMcn()(
                        'Button',
                        {
                            active: activeFilter === item,
                            color,
                            body: bodyColor,
                        },
                    ))}
                >
                    #
                    {item}
                </button>
            </div>
        );
    };

    renderItems = () => {
        const { tags } = this.props;

        return (
            <Swiper {...this.sliderParams()}>
                {this.renderItem('all')}
                {tags.map(item => this.renderItem(item))}
            </Swiper>
        );
    };

    render() {
        const { tags } = this.props;

        return (
            <div className={this.getMcn()()}>
                {tags.length ? this.renderItems() : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    onlineGamesFilter: state.games.onlineGamesFilter,
});

export default connect(mapStateToProps)(TagsFilter);
