import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';

import './NavButtons.sass';


class NavButtons extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.string).isRequired,
        current: PropTypes.string.isRequired,
        color: PropTypes.string,
        bodyColor: PropTypes.string,
    };

    static defaultProps = {
        color: 'blue',
        bodyColor: 'default',
    };

    getMcn = () => cn('NavButtons');

    renderItems = () => {
        const {
            items, current, color, bodyColor,
        } = this.props;

        return (
            items.map(item => (
                <li
                    className={this.getMcn()('Item')}
                    key={item}
                >
                    <Link
                        to={`/${item}`}
                        className={classnames(this.getMcn()(
                            'Link',
                            {
                                active: item === current,
                                color,
                                body: bodyColor,
                            },
                        ))}
                    >
                        {item}
                        {' '}
                        games
                    </Link>
                </li>
            ))
        );
    };

    render() {
        return (
            <ul className={this.getMcn()()}>
                {this.renderItems()}
            </ul>
        );
    }
}

export default NavButtons;
