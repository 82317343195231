import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cn } from '@bem-react/classname';
import PropTypes from 'prop-types';
import Footer from '../../components/Footer/Footer';
import * as actionsContent from '../../actions/actionsContent';

import './CostPage.sass';


class CostPage extends Component {
    static propTypes = {
        fetchCost: PropTypes.func.isRequired,
        costPage: PropTypes.shape({
            __htnl: PropTypes.string,
        }).isRequired,
    };

    componentDidMount() {
        const { fetchCost } = this.props;

        fetchCost();
    }

    getMcn = () => cn('CostPage');

    render() {
        const { costPage } = this.props;

        return (
            <>
                <div className={this.getMcn()()}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={costPage} />
                </div>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => ({
    costPage: state.games.costPage,
});

export default connect(mapStateToProps, actionsContent)(CostPage);
