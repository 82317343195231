import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import App from './components/App/App';
import store from './store/index';
import { BASE_PATH } from './constants/index';


const Root = () => (
    <Provider store={store}>
        <BrowserRouter
            basename={`${BASE_PATH}/`}
        >
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </BrowserRouter>
    </Provider>
);

export default Root;
