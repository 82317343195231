import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GamePage from './GamePage';

class GamePageWrapper extends Component {
    static propTypes = {
        match: PropTypes.shape({
            isExact: PropTypes.bool,
            url: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            params: PropTypes.object,
        }).isRequired,
        htmlGames: PropTypes.arrayOf(
            PropTypes.shape({
                file: PropTypes.string,
                id: PropTypes.number,
                uidx: PropTypes.string,
                meta: PropTypes.shape({
                    extra_short_description: PropTypes.string.isRequired,
                    instructions: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    short_description: PropTypes.string,
                }),
                previews: PropTypes.array,
                tag: PropTypes.string,
                type: PropTypes.string,
            }),
        ).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
    };

    getData = () => {
        const {
            match,
            htmlGames,
        } = this.props;

        return htmlGames.filter(item => item.id === +match.params.id);
    };

    render() {
        const { htmlGames } = this.props;
        const [data] = this.getData();

        return (
            <div>
                {htmlGames.length > 0 && Object.keys(data).length > 0
                    ? <GamePage data={data} />
                    : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    htmlGames: state.games.htmlGames,
});

export default connect(mapStateToProps)(GamePageWrapper);
