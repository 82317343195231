import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes, { instanceOf } from 'prop-types';
import { cn } from '@bem-react/classname';
import { withCookies, Cookies } from 'react-cookie';
import LazyLoad from 'react-lazyload';
import { isBrowser } from 'react-device-detect';
import TagsFilter from '../TagsFilter/TagsFilter';
import createTagsList from '../../helpers/creatTagsList';
import placeholder from '../common/images/placeholder/placeholder-220x220.png';
import loader from '../common/images/loader.svg';
import darkBg from '../common/images/placeholder/dark-bg_303x171.png';
import * as actionsContent from '../../actions/actionsContent';
import { BASE_PATH } from '../../constants';
import getExpiresTime from '../../helpers/getExpiresTime';

import './GamesListLarge.sass';


class GamesListLarge extends Component {
    static propTypes = {
        androidGames: PropTypes.arrayOf(
            PropTypes.shape({
                fileHash: PropTypes.string,
                id: PropTypes.number,
                previews: PropTypes.array,
                tag: PropTypes.string,
                type: PropTypes.string,
                uidx: PropTypes.string,
            }),
        ).isRequired,
        androidGamesFilter: PropTypes.string.isRequired,
        setAndroidFilter: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        cookies: instanceOf(Cookies).isRequired,
        popupOpen: PropTypes.func.isRequired,
    };

    getMcn = () => cn('GamesListLarge');

    clickHandler = (event) => {
        const { cookies, location, popupOpen } = this.props;

        if (isBrowser) {
            event.preventDefault();
            popupOpen();
            return;
        }

        cookies.set('lastPath', location.pathname, { path: '/', expires: getExpiresTime(10) });
    };

    renderItem = (item) => {
        const {
            id, previews, name, fileHash, uidx,
        } = item;
        let imgSrc;

        try {
            imgSrc = previews.filter(i => i.type === '690_390')[0].link;
        } catch (e) {
            imgSrc = placeholder;
        }

        return (
            <div className={this.getMcn()('Item')} key={id}>
                <a
                    className={this.getMcn()('Link')}
                    href={`${BASE_PATH}/storage/d/${uidx}/${fileHash}/`}
                    onClick={this.clickHandler}
                >
                    <span className={this.getMcn()('ImageHolder')}>
                        <img className={this.getMcn()('Placeholder')} src={darkBg} alt="" />
                        <img height={42} width={42} className={this.getMcn()('Loader')} src={loader} alt="" />
                        <LazyLoad offset={100}>
                            <img
                                src={`${BASE_PATH}/bstorage/${imgSrc}`}
                                alt=""
                                className={this.getMcn()('Img')}
                            />
                        </LazyLoad>
                    </span>
                    <span className={this.getMcn()('NameWrap')}>
                        <span className={this.getMcn()('Name')}>{name}</span>
                    </span>
                </a>
            </div>
        );
    };

    renderItems = () => {
        const { androidGames, androidGamesFilter } = this.props;
        const filteredGames = androidGamesFilter === ''
            ? androidGames
            : androidGames.filter(item => item.tag === androidGamesFilter);

        return (
            <div className={this.getMcn()('Wrapper')}>
                <div className={this.getMcn()('List')}>
                    {filteredGames.map(item => this.renderItem(item))}
                </div>
            </div>
        );
    };

    setFilter = (tag) => {
        const { setAndroidFilter } = this.props;

        setAndroidFilter(tag);
    };

    render() {
        const { androidGames, androidGamesFilter } = this.props;

        return (
            <div className={this.getMcn()()}>
                <TagsFilter
                    onFilterSet={this.setFilter}
                    tags={createTagsList(androidGames)}
                    current={androidGamesFilter}
                    color="green"
                    bodyColor="white"
                />
                {androidGames.length ? this.renderItems() : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    androidGames: state.games.androidGames,
    androidGamesFilter: state.games.androidGamesFilter,
});

export default connect(mapStateToProps, actionsContent)(withRouter(withCookies(GamesListLarge)));
