export default function (gamesList) {
    const tags = new Set(gamesList.map((item) => {
        if (item.tag === '') return 'notag';
        return item.tag;
    }));
    const tagsToRender = [];
    tags.forEach((item) => {
        tagsToRender.push(item);
    });
    return tagsToRender;
}
