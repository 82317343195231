import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import Swiper from 'react-id-swiper';
import darkBg from '../common/images/placeholder/dark-bg_275x155.png';
import placeholder from '../common/images/placeholder/placeholder-275x155.png';
import { BASE_PATH } from '../../constants';
import * as actionsContent from '../../actions/actionsContent';

import './SliderWide.sass';


class SliderWide extends Component {
    static propTypes = {
        androidGames: PropTypes.arrayOf(
            PropTypes.shape({
                file: PropTypes.string,
                fileHash: PropTypes.string,
                id: PropTypes.number,
                previews: PropTypes.array,
                tag: PropTypes.string,
                type: PropTypes.string,
                uidx: PropTypes.string,
            }),
        ).isRequired,
        popupOpen: PropTypes.func.isRequired,
    };

    getMcn = () => cn('SliderWide');

    sliderParams = () => ({
        slidesPerView: 3,
        spaceBetween: 10,
        loop: true,
        preloadImages: false,
        lazy: true,
        pagination: {
            el: '.swiper-pagination',
        },
        renderPagination: () => <span className="swiper-pagination SliderWide-Pagination">&nbsp;</span>,
        breakpointsInverse: true,
        breakpoints: {
            520: {
                spaceBetween: 20,
            },
        },
    });

    preventAndPopup = (event) => {
        if(isBrowser) {
            event.preventDefault();
            const { popupOpen } = this.props;

            popupOpen();
        }
    };

    renderItem = (item) => {
        const {
            id, name, previews, tag, fileHash, uidx,
        } = item;
        let imgSrc;

        try {
            imgSrc = previews.filter(i => i.type === '690_390')[0].link;
        } catch (e) {
            imgSrc = placeholder;
        }

        return (
            <div className={this.getMcn()('Item')} key={id} data-id={id}>
                <a
                    className={this.getMcn()('Link')}
                    href={`${BASE_PATH}/storage/d/${uidx}/${fileHash}/`}
                    onClick={this.preventAndPopup}
                >
                    <span className={this.getMcn()('ImgHolder')}>
                        <img className={this.getMcn()('Placeholder')} src={darkBg} alt="" />
                        <img
                            className={classnames(this.getMcn()('Img'), 'swiper-lazy')}
                            data-src={`${BASE_PATH}/bstorage/${imgSrc}`}
                            alt=""
                        />
                        <div className={classnames('swiper-lazy-preloader', 'swiper-lazy-preloader-white')} />
                    </span>
                    <span className={this.getMcn()('Content')}>
                        <span className={this.getMcn()('Name')}>{name}</span>
                        <span className={this.getMcn()('Tag')}>
                            #
                            {tag}
                        </span>
                    </span>
                </a>
            </div>
        );
    };

    renderItems = () => {
        const { androidGames } = this.props;

        return (
            <Swiper {...this.sliderParams()}>
                {androidGames.map(item => this.renderItem(item))}
            </Swiper>
        );
    };

    render() {
        const { androidGames } = this.props;

        return (
            <div className={this.getMcn()()}>
                <div className={this.getMcn()('Wrapper')}>
                    {androidGames.length ? this.renderItems() : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    androidGames: state.games.androidGames,
});

export default connect(mapStateToProps, actionsContent)(SliderWide);
