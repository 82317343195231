import axios from 'axios';
import {
    ACTION_FETCH_DATA,
    ACTION_FETCH_CONDITIONS,
    ACTION_FETCH_FOOTER,
    ACTION_FETCH_COST,
    ACTION_SET_ONLINE_FILTER,
    ACTION_SET_ANDROID_FILTER,
    ACTION_RESIVE_ERROR,
    ACTION_RESET_ERROR,
    ACTION_POPUP_OPEN,
    ACTION_POPUP_CLOSE,
    BASE_PATH,
} from '../constants';
import restructureData from '../helpers/restructureData';

export const fetchData = () => async (dispatch) => {
    try {
        const res = await axios.get(`${BASE_PATH}/api?lang=ua`);

        const [htmlGamesData] = res.data.categories.filter(item => item.alias === 'html-games');
        const [androidGamesData] = res.data.categories.filter(item => item.alias === 'android');

        const htmlGames = restructureData(htmlGamesData);
        const androidGames = restructureData(androidGamesData);

        dispatch({
            type: ACTION_FETCH_DATA,
            payload: {
                htmlGames: htmlGames || [],
                androidGames: androidGames || [],
            },
        });
    } catch (error) {
        dispatch({
            type: ACTION_RESIVE_ERROR,
            payload: error,
        });
    }
};

export const fetchConditions = () => async (dispatch) => {
    try {
        const res = await axios.get(`${BASE_PATH}/text/online-games/oferta/1`);

        dispatch({
            type: ACTION_FETCH_CONDITIONS,
            payload: res.data,
        });
    } catch (error) {
        dispatch({
            type: ACTION_FETCH_CONDITIONS,
            payload: 'Помилка завантаження тексту',
        });
    }
};

export const fetchFooter = () => async (dispatch) => {
    try {
        const res = await axios.get(`${BASE_PATH}/text/online-games/footer/1`);

        dispatch({
            type: ACTION_FETCH_FOOTER,
            payload: res.data,
        });
    } catch (error) {
        dispatch({
            type: ACTION_FETCH_FOOTER,
            payload: 'Помилка завантаження тексту',
        });
    }
};

export const fetchCost = () => async (dispatch) => {
    try {
        const res = await axios.get(`${BASE_PATH}/text/online-games/pricepage/1`);

        dispatch({
            type: ACTION_FETCH_COST,
            payload: res.data,
        });
    } catch (error) {
        dispatch({
            type: ACTION_FETCH_COST,
            payload: 'Помилка завантаження тексту',
        });
    }
};

export const setOnlineFilter = tag => ({
    type: ACTION_SET_ONLINE_FILTER,
    payload: tag,
});

export const setAndroidFilter = tag => ({
    type: ACTION_SET_ANDROID_FILTER,
    payload: tag,
});

export const resetError = () => ({
    type: ACTION_RESET_ERROR,
});

export const popupOpen = () => {
    document.body.classList.add('popup-open');

    return {
        type: ACTION_POPUP_OPEN,
    };
};

export const popupClose = () => {
    setTimeout(() => {
        document.body.classList.remove('popup-open');
    }, 300);

    return {
        type: ACTION_POPUP_CLOSE,
    };
};
