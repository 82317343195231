import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import * as actionsContent from '../../actions/actionsContent';

import './ErrorPage.sass';


const ErrorPage = ({ pageType, resetError }) => {
    const mcn = cn('ErrorPage');

    const renderText = () => {
        switch (pageType) {
        case '404':
            return 'Сторінка не знайдена';

        case 'wrong':
            return 'щось не так';

        default:
            return '';
        }
    };

    return (
        <div className={mcn()}>
            {pageType === 'wrong'
                ? <p className={mcn('LgText')}>Отакої</p>
                : null}
            <p className={mcn('Text')}>
                {renderText()}
            </p>
            {pageType === '404'
                ? <p className={mcn('LgText')}> 404 </p>
                : null}
            <Link
                className={classnames(mcn('Button'), cn('Button')({ style: 'dark' }))}
                type="button"
                to="/"
                onClick={resetError()}
            >
                На головну
            </Link>
        </div>
    );
};

ErrorPage.propTypes = {
    pageType: PropTypes.string,
    resetError: PropTypes.func.isRequired,
};

ErrorPage.defaultProps = {
    pageType: '404',
};

export default withRouter(connect(null, actionsContent)(ErrorPage));
