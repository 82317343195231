import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.element.isRequired,
    };

    state = { hasError: false };

    componentDidCatch(error, info) {
        this.setState({ hasError: true });

        // eslint-disable-next-line no-console
        console.error(error, info);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return <h1>Щось пішло не так</h1>;
        }
        return children;
    }
}

export default ErrorBoundary;
