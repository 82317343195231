import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import LazyLoad from 'react-lazyload';
import TagsFilter from '../TagsFilter/TagsFilter';
import * as actionsContent from '../../actions/actionsContent';
import createTagsList from '../../helpers/creatTagsList';
import darkBg from '../common/images/placeholder/dark-bg_220x220.png';
import placeholder from '../common/images/placeholder/placeholder-220x220.png';
import loader from '../common/images/loader.svg';
import { BASE_PATH } from '../../constants';

import './GamesList.sass';


class GamesList extends PureComponent {
    static propTypes = {
        slidesPerColumn: PropTypes.number,
        useSlider: PropTypes.bool,
        htmlGames: PropTypes.arrayOf(
            PropTypes.shape({
                file: PropTypes.string,
                id: PropTypes.number,
                previews: PropTypes.array,
                type: PropTypes.string,
            }),
        ).isRequired,
        onlineGamesFilter: PropTypes.string.isRequired,
        filterColor: PropTypes.string,
        filterBody: PropTypes.string,
        setOnlineFilter: PropTypes.func.isRequired,
    };

    static defaultProps = {
        slidesPerColumn: 3,
        useSlider: true,
        filterColor: 'main',
        filterBody: 'white',
    };

    constructor(props) {
        super(props);
        this.sliderRef = React.createRef();
    }

    state = {
        slider: null,
    };

    componentDidUpdate(prevProps) {
        const { onlineGamesFilter } = this.props;
        const { slider } = this.state;

        if (onlineGamesFilter !== prevProps.onlineGamesFilter && slider) {
            setTimeout(() => {
                slider.current.swiper.lazy.load();
                slider.current.swiper.slideTo(0, 0);
            }, 0);
        }
    }

    getMcn = () => cn('GamesList');

    sliderParams = () => ({
        slidesPerColumn: 3,
        slidesPerView: 3,
        spaceBetween: 20,
        slidesPerColumnFill: 'row',
        observer: true,
        preloadImages: false,
        lazy: true,
        watchSlidesVisibility: true,
        on: {
            init: () => {
                this.setState({
                    slider: this.sliderRef,
                });
            },
        },
    });

    renderItem = (item, noSlider = false) => {
        const { id, previews, name } = item;
        let imgSrc;

        try {
            imgSrc = previews.filter(i => i.type === 'icon_512x512')[0].link;
        } catch (e) {
            imgSrc = placeholder;
        }

        if (noSlider) {
            return (
                <div
                    className={this.getMcn()('Item', { noslider: noSlider })}
                    key={id}
                >
                    <Link to={`/game-page/${id}`} className={this.getMcn()('Link')}>
                        <span className={this.getMcn()('ImgHolder')}>
                            <img src={darkBg} className={this.getMcn()('Placeholder')} alt="" />
                            <img height={42} width={42} className={this.getMcn()('Loader')} src={loader} alt="" />
                            <LazyLoad offset={200}>
                                <img
                                    src={`${BASE_PATH}/bstorage/${imgSrc}`}
                                    alt=""
                                    className={this.getMcn()('Img')}
                                />
                            </LazyLoad>
                        </span>
                        <span className={this.getMcn()('NameWrap')}>
                            <span className={this.getMcn()('Name')}>{name}</span>
                        </span>
                    </Link>
                </div>
            );
        }

        return (
            <div
                className={this.getMcn()('Item', { noslider: noSlider })}
                key={id}
            >
                <Link to={`/game-page/${id}`} className={this.getMcn()('Link')}>
                    <span className={this.getMcn()('ImgHolder')}>
                        <img src={darkBg} className={this.getMcn()('Placeholder')} alt="" />
                        <img
                            data-src={`${BASE_PATH}/bstorage/${imgSrc}`}
                            alt=""
                            className={classnames(this.getMcn()('Img'), 'swiper-lazy')}
                        />
                        <div className={classnames('swiper-lazy-preloader', 'swiper-lazy-preloader-white')} />
                    </span>
                    <span className={this.getMcn()('NameWrap')}>
                        <span className={this.getMcn()('Name')}>{name}</span>
                    </span>
                </Link>
            </div>
        );
    };

    renderItems = () => {
        const { htmlGames, onlineGamesFilter, useSlider } = this.props;
        const filteredGames = onlineGamesFilter === ''
            ? htmlGames
            : htmlGames.filter(item => item.tag === onlineGamesFilter);

        return useSlider
            ? (
                <div className={this.getMcn()('SliderWrapper')}>
                    <Swiper
                        {...this.sliderParams()}
                        ref={this.sliderRef}
                    >
                        {filteredGames.map(item => this.renderItem(item))}
                    </Swiper>
                </div>
            )
            : (
                <div className={this.getMcn()('Wrapper')}>
                    <div className={this.getMcn()('List')}>
                        {filteredGames.map(item => this.renderItem(item, true))}
                    </div>
                </div>
            );
    };

    setFilter = (tag) => {
        const { setOnlineFilter } = this.props;

        setOnlineFilter(tag);
    };

    render() {
        const {
            htmlGames, onlineGamesFilter, filterColor, filterBody,
        } = this.props;

        return (
            <div className={this.getMcn()()}>
                <TagsFilter
                    onFilterSet={this.setFilter}
                    tags={createTagsList(htmlGames)}
                    current={onlineGamesFilter}
                    color={filterColor}
                    bodyColor={filterBody}
                />
                {htmlGames.length ? this.renderItems() : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    htmlGames: state.games.htmlGames,
    onlineGamesFilter: state.games.onlineGamesFilter,
});

export default connect(mapStateToProps, actionsContent)(GamesList);
