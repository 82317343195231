import React from 'react';
import PropTypes from 'prop-types';
import { classnames } from '@bem-react/classnames';

import './Corner.sass';
import { cn } from '@bem-react/classname';


const Corner = (props) => {
    const { lgText, text, extraclass } = props;
    const mcn = cn('Corner');

    return (
        <div className={classnames('Corner', extraclass)}>
            <span className={mcn('Text')}>
                <i>{lgText}</i>
                <br />
                {text}
            </span>
        </div>
    );
};

Corner.propTypes = {
    lgText: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    extraclass: PropTypes.string,
};

Corner.defaultProps = {
    extraclass: '',
};

export default Corner;
