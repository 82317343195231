import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import Swiper from 'react-id-swiper';
import Corner from '../Corner/Corner';
import placeholder from '../common/images/placeholder/placeholder-360x640.png';
import { BASE_PATH } from '../../constants';

import './SliderPre.sass';


class SliderPre extends Component {
    static propTypes = {
        androidGames: PropTypes.arrayOf(
            PropTypes.shape({
                file: PropTypes.string,
                id: PropTypes.number,
                previews: PropTypes.array,
                tag: PropTypes.string,
                type: PropTypes.string,
            }),
        ).isRequired,
    };

    getMcn = () => cn('SliderPre');

    sliderParams = () => ({
        slidesPerView: 3,
        observer: true,
        breakpointsInverse: true,
        breakpoints: {
            520: {
                slidesPerView: 4,
            },
        },
    });

    renderItem = (item) => {
        const { id, link } = item;

        return (
            <div className={this.getMcn()('Item')} key={id}>
                <img className={this.getMcn()('Img')} src={`${BASE_PATH}/bstorage/${link}` || placeholder} alt="" />
            </div>
        );
    };

    renderItems = () => {
        const { androidGames } = this.props;

        try {
            const gameOfTheWeek = androidGames.filter(i => i.gameOfTheWeek)[0];
            const previewPictures = gameOfTheWeek.previews.filter(i => i.type === '240_320');

            return (
                <Swiper {...this.sliderParams()}>
                    {previewPictures.map(item => this.renderItem(item))}
                </Swiper>
            );
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);

            return null;
        }
    };

    render() {
        const { androidGames } = this.props;

        return (
            <div className={this.getMcn()()}>
                <div className={this.getMcn()('Wrapper')}>
                    {androidGames.length ? this.renderItems() : null}
                    <Corner lgText="Гра" text="тижня" extraclass="SliderPre-Corner" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    androidGames: state.games.androidGames,
});

export default connect(mapStateToProps)(SliderPre);
