import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes, { instanceOf } from 'prop-types';
import { isBrowser } from 'react-device-detect';
import { cn } from '@bem-react/classname';
import { withCookies, Cookies } from 'react-cookie';
import * as actionsContent from '../../actions/actionsContent';
import SliderGame from '../../components/SliderGame/SliderGame';
import SliderTall from '../../components/SliderTall/SliderTall';
import Footer from '../../components/Footer/Footer';
import { BASE_PATH } from '../../constants';
import getExpiresTime from '../../helpers/getExpiresTime';

import './GamePage.sass';


class GamePage extends Component {
    static propTypes = {
        data: PropTypes.shape({
            fileHash: PropTypes.string,
            id: PropTypes.number,
            name: PropTypes.string,
            previews: PropTypes.array,
            uidx: PropTypes.string,
            meta: PropTypes.shape({
                extra_short_description: PropTypes.string.isRequired,
                instructions: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                short_description: PropTypes.string,
            }),
        }).isRequired,
        match: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        cookies: instanceOf(Cookies).isRequired,
        popupOpen: PropTypes.func.isRequired,
    };

    setCoookies = () => {
        const { cookies, location } = this.props;
        cookies.set('lastPath', location.pathname, { path: '/', expires: getExpiresTime(10) });
    };

    renderContentLink = () => {
        const { popupOpen, data: { fileHash, uidx } } = this.props;
        const gameUrl = `${BASE_PATH}/storage/d/${uidx}/${fileHash}/`;

        return isBrowser
            ? (
                <button
                    className={cn('Button')({ style: 'dark' })}
                    type="button"
                    onClick={popupOpen}
                >
                    Грати
                </button>
            )
            : (
                <a
                    className={cn('Button')({ style: 'dark' })}
                    href={gameUrl}
                    onClick={this.setCoookies}
                >
                    Грати
                </a>
            );
    };

    renderGame = () => {
        const {
            data: {
                meta, previews, name,
            },
        } = this.props;
        const mcn = cn('GamePage');

        try {
            const {
                instructions: instr,
                short_description: shortDescr,
                extra_short_description: extraShortDescr,
            } = meta;
            const imgSrc = previews.filter(i => i.type === 'title_800x200').length
                ? previews.filter(i => i.type === 'title_800x200')[0].link
                : '';
            const sliderImgsTall = previews.filter(i => i.type === 'screens_480x800');
            const sliderImgsWide = previews.filter(i => i.type === 'screens_800x480');
            const vertical = !!sliderImgsTall.length;
            const sliderPreviews = vertical ? sliderImgsTall : sliderImgsWide;

            return (
                <>
                    <div className={mcn()}>
                        <img src={`${BASE_PATH}/bstorage/${imgSrc}`} alt="" className={mcn('TitleImage')} />
                        <h1 className={mcn('Title')}>{name}</h1>
                        <hr className={mcn('Hr')} />
                        <div className={mcn('Content')}>
                            <h3 className={mcn('Big')}>{extraShortDescr}</h3>
                            <p className={mcn('Text')}>{shortDescr}</p>
                        </div>
                        {sliderPreviews.length
                            ? <SliderGame previews={sliderPreviews} vertical={vertical} />
                            : null}
                        <div className={cn('Button')('Wrapper')}>
                            {this.renderContentLink()}
                        </div>
                        <div className={mcn('Content')}>
                            <h3 className={mcn('Title')}>Інструкція</h3>
                            <p className={mcn('Text')}>{instr}</p>
                        </div>
                        <hr className={mcn('Hr')} />
                        <h3 className={mcn('Title')}>Більше ігор</h3>
                        <SliderTall styleType="dark" />
                    </div>
                    <Footer backgroundColor="main" />
                </>
            );
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return <div>Помилка даних</div>;
        }
    };

    render() {
        return this.renderGame();
    }
}

export default connect(null, actionsContent)(withRouter(withCookies(GamePage)));
