import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import logo from './images/logo.svg';

import './Header.sass';


const Header = ({ isMainPage }) => {
    const mcn = cn('Header');

    return (
        <header className={mcn()}>
            <Link to="/" className={mcn('Link')}>
                {isMainPage ? <h1 className={mcn('Title')}>Games</h1> : null}
                <img src={logo} className={mcn('Logo')} alt="" />
            </Link>
        </header>
    );
};

Header.propTypes = {
    isMainPage: PropTypes.bool.isRequired,
};

export default Header;
