import {
    ACTION_FETCH_DATA,
    ACTION_FETCH_CONDITIONS,
    ACTION_FETCH_FOOTER,
    ACTION_FETCH_COST,
    ACTION_SET_ONLINE_FILTER,
    ACTION_SET_ANDROID_FILTER,
    ACTION_RESIVE_ERROR,
    ACTION_POPUP_OPEN,
    ACTION_POPUP_CLOSE,
} from '../constants';

const initialState = {
    htmlGames: [],
    androidGames: [],
    onlineGamesFilter: '',
    androidGamesFilter: '',
    error: false,
    errorMessage: '',
    conditions: { __html: '' },
    footer: { __html: '' },
    costPage: { __html: '' },
    popupIsOpen: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
    case ACTION_FETCH_DATA:
        return { ...state, ...payload };

    case ACTION_FETCH_CONDITIONS:
        return { ...state, conditions: { __html: payload } };

    case ACTION_FETCH_FOOTER:
        return { ...state, footer: { __html: payload } };

    case ACTION_FETCH_COST:
        return { ...state, costPage: { __html: payload } };

    case ACTION_SET_ONLINE_FILTER:
        return { ...state, onlineGamesFilter: payload };

    case ACTION_SET_ANDROID_FILTER:
        return { ...state, androidGamesFilter: payload };

    case ACTION_RESIVE_ERROR:
        return {
            ...state,
            error: true,
            errorMessage: payload,
        };

    case ACTION_POPUP_OPEN:
        return { ...state, popupIsOpen: true };

    case ACTION_POPUP_CLOSE:
        return { ...state, popupIsOpen: false };

    default:
        return state;
    }
};
