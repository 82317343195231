export default (games = { contents: [] }) => (
    games.contents.map((item) => {
        const [tag] = item.tags.filter(i => i.type === 'tag').map(i => i.display_name);
        const [gameOfTheWeek] = item.tags.filter(i => i.type === 'gameOfTheWeek').map(() => true);
        const [bestGame] = item.tags.filter(i => i.type === 'bestGame').map(() => true);

        return {
            id: item.id,
            name: item.name,
            type: item.type,
            file: item.file,
            fileHash: item.file_hash,
            previews: item.previews,
            meta: item.meta_params,
            uidx: item.uidx,
            tag,
            gameOfTheWeek: gameOfTheWeek || false,
            bestGame: bestGame || false,
        };
    }));
