export const ACTION_FETCH_DATA = 'ACTION_FETCH_DATA';
export const ACTION_FETCH_CONDITIONS = 'ACTION_FETCH_CONDITIONS';
export const ACTION_FETCH_FOOTER = 'ACTION_FETCH_FOOTER';
export const ACTION_FETCH_COST = 'ACTION_FETCH_COST';
export const ACTION_SET_ONLINE_FILTER = 'ACTION_SET_ONLINE_FILTER';
export const ACTION_SET_ANDROID_FILTER = 'ACTION_SET_ANDROID_FILTER';
export const ACTION_RESIVE_ERROR = 'ACTION_RESIVE_ERROR';
export const ACTION_RESET_ERROR = 'ACTION_RESET_ERROR';
export const ACTION_POPUP_OPEN = 'ACTION_POPUP_OPEN';
export const ACTION_POPUP_CLOSE = 'ACTION_POPUP_CLOSE';

export const BASE_PATH = '';
