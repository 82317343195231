import React, { Component } from 'react';
import { cn } from '@bem-react/classname';
import NavButtons from '../../components/NavButtons/NavButtons';
import GamesListLarge from '../../components/GamesListLarge/GamesListLarge';
import Footer from '../../components/Footer/Footer';

import './AndroidPage.sass';


class AndroidPage extends Component {
    navButtonsProps = () => ({
        color: 'green',
        bodyColor: 'white',
        current: 'android',
        items: [
            'online',
            'android',
        ],
    });

    render() {
        const mcn = cn('AndroidPage');

        return (
            <>
                <div className={mcn()}>
                    <NavButtons {...this.navButtonsProps()} />
                    <GamesListLarge />
                </div>
                <Footer />
            </>
        );
    }
}

export default AndroidPage;
