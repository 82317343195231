import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import Footer from '../../components/Footer/Footer';
import * as actionsContent from '../../actions/actionsContent';


import './ConditionsPage.sass';


class ConditionsPage extends Component {
    static propTypes = {
        fetchConditions: PropTypes.func.isRequired,
        conditions: PropTypes.shape({
            __html: PropTypes.string,
        }).isRequired,
    };

    componentDidMount() {
        const { fetchConditions } = this.props;

        fetchConditions();
    }

    getMcn = () => cn('ConditionsPage');

    render() {
        const { conditions } = this.props;

        return (
            <>
                {/* eslint-disable-next-line react/no-danger */}
                <div className={this.getMcn()('Content')} dangerouslySetInnerHTML={conditions} />
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => ({
    conditions: state.games.conditions,
});

export default connect(mapStateToProps, actionsContent)(ConditionsPage);
