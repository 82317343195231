import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';

import './Button.sass';


const Button = (props) => {
    const { text, link, styleType } = props;
    const mcn = cn('Button');

    return (
        <div className={mcn('Wrapper')}>
            <Link to={link} className={mcn({ style: styleType })}>
                {text}
            </Link>
        </div>
    );
};

Button.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    styleType: PropTypes.string,
};

Button.defaultProps = {
    styleType: 'light',
};

export default Button;
