import React, { Component } from 'react';
import { isIOS } from 'react-device-detect';
import NavButtons from '../../components/NavButtons/NavButtons';
import GamesList from '../../components/GamesList/GamesList';
import Footer from '../../components/Footer/Footer';


class OnlinePage extends Component {
    navButtonsProps = () => ({
        color: 'main',
        bodyColor: 'blue',
        current: 'online',
        items: [
            'online',
            'android',
        ],
    });

    render() {
        return (
            <>
                <div className="OnlinePage">
                    {isIOS ? null : <NavButtons {...this.navButtonsProps()} />}
                    <GamesList useSlider={false} filterColor="main" filterBody="blue" />
                </div>
                <Footer />
            </>
        );
    }
}

export default OnlinePage;
