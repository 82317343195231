/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { render } from 'react-dom';
import 'normalize.css';
import 'react-id-swiper/src/styles/scss/swiper.scss';
import './components/common/styles/main.sass';
import Root from './root';
import store from './store';
import { fetchData } from './actions/actionsContent';

store.dispatch(fetchData());


const mounthNode = document.getElementById('root');
render(<Root />, mounthNode);

const { hot } = module;

if (hot) {
    hot.accept();
}
